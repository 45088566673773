import { connect } from 'react-redux'
import get from 'lodash/get'

import FooterView from './FooterView'


export default connect(state => ({
  footerData: get(state, 'cmsInfo.data.footer.menu.sections', []),
  bottomData: get(state, 'cmsInfo.data.copyright_bottom.menu.sections', []),
}))(FooterView)
