export const socialIcon = {
  facebook: ['fab', 'facebook-square'],
  twitter: ['fab', 'twitter'],
  linkedin: ['fab', 'linkedin'],
  youtube: ['fab', 'youtube-square'],
  snapchat: ['fab', 'snapchat-ghost'],
  instagram: ['fab', 'instagram-square'],
  blog: ['fab', 'blogger-b'],
  tiktok: ['fa-kit', 'tiktok-square'],
}
