import { Row, Col } from 'antd'
import get from 'lodash/get'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { socialIcon } from './utils'
import { Container, Typography } from 'common/widgets'
import classNames from './layout.module.scss'
import logoBoa from 'img/logo.svg'
import logoBoaFr from 'img/logo-fr.svg'
import reiseGarantieDe from 'img/reisegarantie-de.svg'
import reiseGarantieFr from 'img/reisegarantie-fr.svg'
import { useTranslations } from 'common/language'
import { ServiceReviewBadge } from 'common/widgets/blocks/ProductReviews'


Footer.propTypes = {
  footerData: PropTypes.array.isRequired,
  bottomData: PropTypes.array.isRequired,
}

export default function Footer({ footerData, bottomData }) {
  const { languageCode } = useTranslations()
  return (
    <footer className={classNames.footer}>
      <Container size="xl">
        <Row gutter={[{ xs: 0, lg: 30 }, { xs: 26, sm: 26, lg: 35 }]}>
          <Col span={24} lg={8}>
            <img
              alt="Boalingua logo"
              src={['default', 'en', 'de'].includes(languageCode) ? logoBoa : logoBoaFr}
              className={classNames.logoImage}
            />
          </Col>
        </Row>
        <Row className={classNames.menuRow} gutter={[{ xs: 0, lg: 30 }, { xs: 16, sm: 30, lg: 50 }]}>
          {footerData.map((item, index) => (
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 12, order: 1 }}
              md={6}
              lg={6}
              key={index}
            >
              {item.kind === 'reevoo' ? (
                <Fragment>
                  <ServiceReviewBadge />
                  <div>
                    <a
                      href={['default', 'en', 'de'].includes(languageCode)
                        ? 'https://www.garantiefonds.ch/portrait/auf-einen-blick'
                        : 'https://www.garantiefonds.ch/fr/portrait/en-un-coup-doeil'
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={['default', 'en', 'de'].includes(languageCode) ? reiseGarantieDe : reiseGarantieFr}
                        alt="Reisegarantie"
                        className={classNames.reisegarantie}
                        width="185"
                        height="55"
                        loading="lazy"
                      />
                    </a>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <Typography
                    variant="footer-title"
                    color={item.is_primary_color ? 'teal' : 'dark'}
                    tag={item.url !== '#' ? 'a' : undefined}
                    href={item.url !== '#' ? item.url : undefined}
                    target={item.is_open_in_new_tab ? '_blank' : undefined}
                    rel={item.is_nofollow ? 'nofollow' : undefined}
                  >
                    {item.title}
                  </Typography>
                  {item.kind === 'social_media' && (
                    <div className={classNames.mediaLinkRow}>
                      {get(item, 'subsections', []).map(item => (
                        // eslint-disable-next-line react/jsx-no-target-blank
                        <a
                          key={item.title}
                          href={item.url}
                          aria-label={`Follow us on ${item.title}`}
                          className={cx(classNames.mediaLink, item.is_primary_color && classNames.primary)}
                          target={item.is_open_in_new_tab ? '_blank' : undefined}
                          rel={[item.is_open_in_new_tab && 'noreferrer', item.is_nofollow && 'nofollow'].filter(Boolean).join(' ') || undefined}
                        >
                          <FontAwesomeIcon icon={socialIcon[item.title]} />
                        </a>
                      )
                      )}
                    </div>
                  )}
                  {item.kind === 'regular' && item.subsections && item.subsections.map((subsection, index) => (
                    <div key={index}>
                      <Typography
                        className={classNames.footerLink}
                        color={subsection.is_primary_color ? 'teal' : 'dark'}
                        variant="footer-link"
                        tag={subsection.url !== '#' ? 'a' : undefined}
                        href={subsection.url}
                        target={subsection.is_open_in_new_tab ? '_blank' : undefined}
                        rel={subsection.is_nofollow ? 'nofollow' : undefined}
                      >
                        {subsection.title}
                      </Typography>
                    </div>
                  ))}
                </Fragment>
              )}
            </Col>
          ))}
        </Row>
        <Row gutter={40} className={classNames.copyLinks}>
          {bottomData.map((link, index) => (
            <Col key={index} className={classNames.bottomCol}>
              <Typography
                variant="footer-link"
                color={link.is_primary_color ? 'teal' : 'dark'}
                tag={link.url !== '#' ? 'a' : undefined}
                href={link.url}
                target={link.is_open_in_new_tab ? '_blank' : undefined}
                rel={link.is_nofollow ? 'nofollow' : undefined}
                className={classNames.bottomLink}
              >
                {link.title}
              </Typography>
            </Col>
          ))}
        </Row>
      </Container>
    </footer>
  )
}
